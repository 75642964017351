.gg_global {
  .btn-sm-merch{
    min-height: 62px;
   
  }
  .card-block-how-to-win-merch-section{
    min-height: unset;
    padding-top: 12px !important;
  }
  .btn-sm-ticket{
    min-height: 62px;
    padding-top: 22px;
  }
  .arena {
    .section {
      &-title {
        font-size: 4rem !important;
      }
      @media (max-width: 500px) {
        &-title {
          font-size: 8vw !important;
        }
      }
    }
  }

  .no_ball {
    .modal-bg {
      .modal-body {
        h4 {
          font-size: 30px;
          @media (max-width: 575px) {
            font-size: 24px;
          }
          @media (max-width: 391px) {
            font-size: 19px;
          }
        }
      }
    }
  }

  //common
  .section {
    &-desc {
      line-height: 1.5;

      @media (max-width: 574px) {
        font-size: 4.5vw;
      }
    }

    &-title {
      line-height: 1.2;
      font-size: 5rem;

      @media only screen and (max-width: 1378px) {
        font-size: 4.5rem;
      }
    }
  }

  .tu-language-select-side {
    font-size: 16px;
    line-height: 1.8;
  }

  .tu-language {
    &::after {
      top: 4px;
    }
  }

  .form-label {
    line-height: 1.4;
  }

  .how-to-win-carousel {
    @media (max-width: 500px) {
      margin-top: 4.5rem;
    }
  }

  .header {
    .tu-language-select {
      &::after {
        right: -1vw !important;

        @media (max-width: 1200px) {
          right: -1.5vw !important;
        }

        @media (max-width: 767.67px) {
          right: -2.5vw !important;
        }
      }
    }
  }

  .invalid-feedback,
  .error_message {
    line-height: 1.4;
    padding-top: 0rem;
    font-size: 2rem;
    // padding-left: 2.7rem;
  }

  .btn {
    &-sm {
      font-size: 2rem;
    }
  }

  .tu-sidebar-list-main {
    ul {
      li {
        a {
          font-size: 16px;

          span {
            font-size: 14px;
          }
        }
      }
    }
  }

  .tu-profile-dropdown {
    .tu-input-wrapper {
      span {
        font-size: 14px;
        padding-right: 20px !important;
      }

      input {
        font-size: 18px !important;
        padding-right: 20px !important;
      }

      li {
        font-size: 14px;
        padding-right: 20px !important;
      }
    }

    p {
      font-size: 18px !important;
      line-height: 2.4rem;
      padding-right: 20px !important;
    }
  }

  .tu-content-wrapper {
    .tu-country-select {
      span {
        font-size: 20px;
      }
    }
  }

  /* Common styles for all resolutions */
  .header {
    .tu-unique-code-wrapper {
      input {
        padding: 2px 0 0 3px;
        font-size: 2rem;
      }

      @media (max-width: 400px) {
        .btn {
          padding: 6px 6px 6px 10px;
        }

        input {
          padding: 0 0 0 6px;
        }
      }

      @media (max-width: 850px) {
        margin-right: 30px;

        .btn,
        input {
          font-size: 12px !important;
        }
      }

      @media (max-width: 767.67px) {
        .btn {
          padding: 6px 6px 6px 10px;
          font-size: 1.2rem;
        }

        input {
          padding: 0 0 0 6px;
          margin-right: 1rem;
          width: max-content;
        }
      }

      @media (max-width: 405px) {
        .btn,
        input {
          font-size: 10px !important;
        }

        margin-left: 5px;
        margin-right: 10px;
      }

      @media (max-width: 345px) {
        margin-left: 0;
        margin-right: 5px;
      }
      @media (max-width: 815px) {
        font-size: 13px !important;
        margin-right: 13px;
      }
      @media (max-width: 369px) {
        margin-right: 10px;
        margin-left: 0;
      }
    }

    .tu-wallet-link {
      font-size: 16px;

      @media (max-width: 815px) {
        margin-right: 30px;

        .btn,
        input {
          font-size: 13px !important;
        }
      }

      @media (max-width: 369px) {
        margin-right: 10px;
        // fton
        font-size: 9px !important;
      }
      /* Default font size for larger screens */
    }

    /* Responsive font sizes and spacing using relative units */
    @media only screen and (min-width: 320px) and (max-width: 400px) {
      .tu-wallet-link {
        line-height: 20px;
      }

      .tu-unique-code-wrapper .btn,
      .tu-unique-code-wrapper input {
        font-size: 8px;
        line-height: 8px;
        padding-left: 6px !important;
      }
    }

    /* Responsive font sizes for different screen widths */
    @media only screen and (min-width: 321px) and (max-width: 778px) {
      .tu-wallet-link {
        line-height: 20px;
      }

      .tu-unique-code-wrapper .btn,
      .tu-unique-code-wrapper input {
        line-height: 10px;
        padding-left: 6px !important;
      }
    }
  }

  //fixes on howToWin ctas

  @media (min-width: 992px) and (max-width: 1210px) {
    .card {
      &-block {
        &-sm {
          .card {
            &-block {
              &-img-top {
                max-width: 10.5rem;
              }
            }
          }
        }
      }
    }

    .btn-sm {
      &-ticket,
      &-merch {
        margin-bottom: 1rem;
        font-size: 1.5rem;
        line-height: 1;
        min-height: 3.6vh;
      }

      &-merch {
        padding-top: 2.5vh;
        
      }
    }
  }

  @media (min-width: 737px) and (max-width: 767px) {
    .card {
      &-block {
        &-how-to-win-merch {
          min-height: 6.7vh !important;
        }
      }
    }
  }

  @media (min-width: 637px) and (max-width: 736px) {
    .btn-sm {
      &-ticket {
        font-size: 3vw !important;
      }

      &-merch {
        font-size: 3vw !important;
        padding-top: 3vw !important;
      }
    }

    .card {
      &-block {
        &-how-to-win-merch {
          min-height: 5vh !important;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .card {
      &-block {
        &-how-to-win-merch {
         
          padding: 1vw 2.2vw !important;
        }
      }
    }

    .btn-sm {
      &-ticket {
        line-height: 1.2;
      }
    }
  }

  @media (max-width: 500px) {
    .card {
      &-block {
        &-how-to-win-merch {
          min-height: 4vh !important;
        }
      }
    }
  }

  //banner section
  .banner {
    @media (min-width: 767px) {
      &-content {
        &-body {
          max-width: 41vw;
        }
      }
    }

    // @media (min-width: 1200.98px) and (max-width: 1492.98px) {
    //   &-content {
    //     &-body {
    //       max-width: 24vw;
    //     }
    //   }
    // }

    @media (max-width: 1199.98px) {
      .section {
        &-title {
          font-size: 3.2vw;
          line-height: 1.2;
        }

        &-sub-title {
          // font-size: 11px;
        }
      }
    }

    @media (max-width: 926.98px) {
      .section {
        &-sub-title {
          //   font-size: 10px;
        }
      }
    }

    @media only screen and (max-width: 767.98px) {
      &-content {
        &-body {
          //   max-width: 33vw !important;
        }
      }

      .section {
        &-title {
          font-size: 5.5vw;
          margin-bottom: 1rem;
          line-height: 1.1;
        }

        &-sub-title {
          line-height: 2.5rem;
        }
      }

      .btn-sm-merch,.btn-sm-ticket{
        font-size: 3vw;
      padding-top: 2vw !important;
        min-height:unset;
        height: 52px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 4px;
       
      }
    }

    @media only screen and (max-width: 575.98px) {
      &-img {
        max-height: 38rem !important;
        object-fit: cover;
        object-position: bottom;
      }

      .object {
        object-position: top;
      }

      .section {
        &-sub-title {
          line-height: 1.7rem;
        }
      }
    }

    @media only screen and (max-width: 500.98px) {
      &-img {
        max-height: 28rem !important;
        object-fit: cover;
        object-position: bottom;
      }

      .object {
        object-position: top;
      }
    }

    @media only screen and (max-width: 325px) {
      .card-block-how-to-win-merch {
        min-height: 52px !important;
      }
    }
  }

  //middle section
  @media only screen and (max-width: 767.98px) {
    .tu-content-wrapper {
      .btn {
        font-size: 1.6rem;
      }

      
    }

    .section {
      &-title {
        &-guj {
          font-size: 7vw;
        }
      }
    }
  }

  //footer section
  @media only screen and (max-width: 991.98px) {
    .footer {
      p {
        font-size: 2.5vw;
      }
    }

    .section {
      &-title {
        &-guj {
          font-size: 6vw;
        }
      }
    }
  }

  //how to win section
  @media (max-width: 500px) {
    .card {
      &-block {
        .btn {
          &-primary {
            font-size: 3.9vw;
            line-height: 18px; //change
          }
        }

      
      }
    }

    .section {
      &-sub-title {
        &-guj {
          font-size: 6vw;
        }
      }
      &-title {
        font-size: 9vw;

        &-guj {
          font-size: 7vw;
        }
      }
    }

    .btn {
      font-size: 1.2rem ; //remove important
      
   
      line-height: 18px ; //remove iportant
    }
    .claim-btn{
      padding: 4px !important;
      margin: 0 10px;
    } 
  }

  //meter section
  @media only screen and (max-width: 767.98px) {
    .meter-sub-heading {
      font-size: 18px;
    }
  }

  //contest section
  .contest {
    .section {
      &-title {
        @media only screen and (max-width: 767.98px) {
          font-size: 4.87vw;
          line-height: 7vw;
          padding: 0 1rem;
        }
      }
    }
  }
.contest-btn{
  @media (max-width:767.98px) {
    padding: 1.6rem 1.1rem !important;
  }
}
  #how_to_win {
    .btn {
      font-size: 3rem;
    }

    @media only screen and (max-width: 767.98px) {
      .btn {
        font-size: 2.4rem;
      }
    }

    @media only screen and (max-width: 575.98px) {
      .btn {
        font-size: 1.8rem;
        line-height: 1.4;
      }
    }

    @media only screen and (max-width: 430.98px) {
      .btn {
        font-size: 1rem !important;
        min-height: 42px;
        padding-top: 1.2rem;

      }
    }
  }

  //popup section
  .profile-success,
  .vote_now_default {
    .modal {
      &-body {
        h4 {
          font-size: 3rem !important;
        }

        @media only screen and (max-width: 575px) {
          h4 {
            font-size: 2.3rem !important;
            padding-top: 2rem;
          }

          h3 {
            font-size: 36px !important;
          }
        }
      }
    }
  }

  .redeem-hi {
    .modal-body {
      h4,
      h3.victory {
        font-size: 30px !important;
        line-height: 1.4;

        @media only screen and (max-width: 575px) {
          font-size: 28px !important;
        }

        @media only screen and (max-width: 430px) {
          font-size: 26px !important;
        }
      }

      @media only screen and (max-width: 430px) {
        h2 {
          font-size: 26px !important;
        }
      }
    }
  }

  /*---------------------------------------------------new css------------------------------------------------------*/
  //popup section
  .modal {
    &-title {
      font-size: 3.6rem;
      line-height: 1.4;
    }

    &-sub-title {
      font-size: 2.2rem;
      line-height: 1.4;
    }

    &-wallet {
      .caption-title {
        // font-size: 1.2rem;
        // line-height: 17px;
        font-size: 1.4rem;
        line-height: 17px;
      }

      .vote-now-btn,
      .unique-code-btn {
        font-size: 1.6rem !important;
        min-height: 42px;

        @media (max-width: 767.98px) {
          font-size: 1.1rem !important;
        }

        @media (max-width: 500.98px) {
          min-height: 62px;
          font-size: 1rem !important;
          padding: 0.5rem;
        }
      }
    }

    &-bg1,
    &-bg2,
    &-bg3,
    &-bg4 {
      .modal {
        &-title {
          font-size: 3.6rem;
          line-height: 1.4 !important;

          @media (max-width: 500px) {
            font-size: 2.3rem !important;
          }
        }

        &-sub-title {
          font-size: 2.1rem;
          line-height: 1.4;

          @media (max-width: 767.67px) {
            font-size: 1.8rem;
          }

          @media (max-width: 550px) {
            font-size: 1.6rem;
          }

          @media (max-width: 400px) {
            font-size: 1.4rem;
          }
        }

        &-content {
          .form-check-label {
            line-height: 1.4;
          }
        }
      }
    }

    .invalid-feedback,
    .error_message {
      padding-top: 0.3rem;
      //font-size: 2rem;
    }

    .btn {
      font-size: 2rem;
      line-height: 1.3;
    }
  }

  .collect-coins {
    .modal-body {
      h2 {
        font-size: 3.4rem;
        line-height: 1.4;
      }

      .victory-wrapper {
        h4 {
          font-size: 3rem;
          line-height: 1.4;
        }
      }
    }
  }

  .display {
    &-3 {
      font-size: 1.8rem;
      line-height: 1.4;
    }
  }

  @media (max-width: 991px) {
    .collect-coins {
      .modal-body {
        .display-3,
        h6 {
          line-height: 1.5;
          font-size: 3.6vw !important;
         
        }
      }
    }
  }

  @media (max-width: 767px) {
    .modal {
      &-title {
        font-size: 2.4rem;
      }

      &-sub-title {
        font-size: 2rem;
      }

      .btn {
        font-size: 1.6rem;
      }

      &-wallet {
        .caption-title {
          font-size: 1.5rem;
        }
      }

      &-invite,
      &-claim-ticket {
        .modal-title {
          font-size: 2.4rem;
          line-height: 1.4;
        }

        .modal-sub-title {
          font-size: 2rem;
        }
      }

      &-claim-ticket {
        .modal-body {
          .modal-right-section {
            h2.claim-head {
              font-size: 2rem;
              line-height: 1.4;
            }
          }
        }

        .mandatory-text {
          font-size: 22px;
        }
      }
    }

    .collect-coins {
      .modal-body {
        .display-3,
        h6 {
          font-size: 1.4rem;
        }

        h2 {
          font-size: 4.6vw;
        }

        .victory-wrapper {
          h4 {
            font-size: 2rem;
          }
        }

        button {
          font-size: 1.6rem;
        }
      }
    }
  }

  @media (min-width: 359.9px) and (max-width: 576px) {
    .modal {
      &-wallet .caption-title {
        font-size: 1rem;
        line-height: 16px;
        height: 20px;
      }
    }
  }

  @media (max-width: 380px) {
    .modal {
      &-wallet {
        .btn {
          font-size: 1rem;
        }
      }
    }

    .collect-coins {
      .modal-body {
        button {
          font-size: 1.2rem;
        }
      }
    }
  }

  @media (max-width: 359.9px) {
    .modal {
      &-wallet .caption-title {
        font-size: 1rem;
        line-height: 16px;
        height: 20px;
      }
    }
  }

  @media (max-width: 327.9px) {
    .collect-coins {
      .modal-body {
        button {
          font-size: 1rem;
        }
      }
    }
  }

  .refer-modal {
    .modal {
      &-right-section {
        .anchor-with-underscore {
          &::after {
            width: 45% !important;

            @media (max-width: 375.9px) {
              width: 60% !important;
            }
          }

          @media (max-width: 575.9px) {
            font-size: 14px !important;
          }
        }
      }
    }
  }

  //logout btn fixes

  .logout-btn {
    @media (max-width: 855px) {
      font-size: 1.8rem !important;
      padding: 1.4vw 4vw;
    }

    @media (max-width: 1757px) {
      font-size: 2rem !important;
      padding: 1vw 2vw;
    }

    @media (max-width: 575px) {
      padding: 0.5rem 1.2rem !important;
      font-size: 1.7rem !important;
    }

    @media (max-width: 501px) {
      padding: 0.5rem 1.2rem !important;
    }

    @media (max-width: 338px) {
      padding: 0.5rem 1.2rem !important;
      font-size: 1.6rem !important;
    }
  }

  // AR banner
  .tu-ar-banner {
    @media (min-width: 320px) and (max-width: 767.98px) {
      width: 84vw !important;
      margin-left: auto;
      display: flex;
      transform: translate(-24%, -50%);
      flex-direction: column;
      align-items: center;
    }

    @media (min-width: 320px) and (max-width: 767.98px) {
      .ar-cta {
        margin-right: 0;
        padding-right: 0;

        a {
          font-size: 12px;
          padding: 7px;
        }
      }
    }

    .ar-title {
      @media (min-width: 320px) and (max-width: 767.98px) {
        font-size: 5vw !important;
      }
    }
  }

  @media (min-width: 320px) {
    .winner-contest {
      .list-item {
        h6 {
          font-size:2.2vw;
        }
      }
    }
  }

  @media (max-width: 575.98px) {
    .title-main {
      font-size: 5.6vw !important;
      line-height: 7.6vw !important;
    }
  }

  .winner-contest {
    .btn {
      &-prev {
        padding: 1.6rem 4rem !important;
        @media (max-width: 767.67px) {
          padding: 0.7rem 3.2rem !important;
        }
      }
    }
    .list {
      &-btns {
        font-size: 2rem !important;
        padding: 1.6rem 3rem;

        @media (max-width: 1200px) {
          font-size: 1.8rem !important;
          padding: 1rem 2.4rem;
        }

        @media (max-width: 435px) {
          font-size: 1.5rem !important;
          padding: 0.7rem 2rem;
        }
      }
    }
  }
@media (max-width:767.98px) {
  .card-block-img-top{
    width: unset;
  }
  .winner-contest .list-avatar {
    max-width: 6vw;
  @media (max-width:420px) {
    max-width: 7vw;
  }
}
}
}
