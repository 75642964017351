

.snap-with-neeraj {
    .wrapper {
        width: 100%;
        // max-width: 450px;
        overflow: hidden;
        margin: 0 auto;
        position: relative;
        text-align: center;
    }

    #canvas {
        visibility: hidden;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
    }

    #video,
    #camera {
        width: 100%;
        position: absolute;
        top: 27%;
        left: 0;
        z-index: 2;
        transform: scaleX(-1);
    }

    #overlay {
        position: relative;
        width: 100%;
        z-index: 3;
    }
}
.capture-wrapper{
    max-width: 453px;
    width: 100%;
}
.tu-capture-frame
{
    width: 100%;
    height: auto;
    bottom: 0;
    right: 0;
    left: 0;
    top: 25%;
z-index: 1;  
}
.capture-img{
    z-index: 2;
}
.capture-btn{
    font-size: 4.8rem;
border-radius: 13.5px;
@media (max-width:575.98px) {
    font-size: 3.2rem;
}
}
.step-btn{
padding: 1.8rem;
font-size: 3.2rem;

}
.step-btn.btn-outline{
    border: 1px solid #fff;
}
.capture-image{
    height: unset;
    margin-top: unset;
    form,.form{
        &-check{
            input{
                width: 20px;
            height: 20px;
            }
        }
        label{
            font-size: 1.4rem;
            text-align: left;
            margin-left: 2rem;
           
        }

    }
}

.capture-fix{
    line-height:1.5 !important;
}

@media(max-width:600px){


    .capture-img{
        margin-top:-15px;
    }
}
