body {
  // padding-top: 64px;
  background-color: #fff !important;
  @media (max-width: 767.9px) {
    // padding-top: 98px;
  }
}

.header {
  background-color: $light;
  position: fixed;
  z-index: 9;
  width: 100%;
  top: 0;
  left: 0;

  .container-fluid {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 64px;
  }

  //Uniques code component
  .tu-unique-code-wrapper {
    border: 1px solid $primary;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 4px 7px 4px 13px;
    margin-right: 45px;

    input {
      font-weight: 500;
      font-size: 22px;
      line-height: 26px;
      width: 170px;
      border: none;
      padding-right: 15px;

      &::placeholder {
        color: $primary;
      }

      &:focus-visible {
        outline: none;
      }
    }

    .btn {
      font-size: 22px;
      padding: 10px 18px;
      border-radius: 9px;
    }
  }

  //language
  .header-top {
    background-color: #002169;

    .container-fluid {
      min-height: 37px;
      @media (max-width: 767.67px) {
        min-height: 30px;
      }
    }

    .tu-language-select {
      .divider,
      span,
      select {
        color: $light;
        font-size: 14px;
        background: transparent;
      }

      .divider {
        border-left: 2px solid $light;
      }
    }
  }

  .tu-language-select {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;

    .divider {
      display: inline-block;
      margin: 0 10px;
      border-left: 3px solid #002169;
      height: 20px;
      vertical-align: middle;
    }

    span {
      color: #002169;
      font-size: 24px;

      @media (max-width: 992.98px) {
        font-size: 16px;
      }
    }

    &::after {
      position: absolute;
      content: '';
      background-image: url('~/public/assets/images/blue-dropdown.svg');
      font-size: 14px;
      top: 14px;
      right: 0vw;
      width: 17px;
      height: 17px;
      background-repeat: no-repeat;
      background-size: contain;

      @media (max-width: 992px) {
        top: 8.5px;
        width: 14px;
        height: 14px;
      }

      @media (max-width: 767.67px) {
        background-image: url('~/public/assets/images/white-dropdown.png');
        width: 10px;
        height: 10px;
      }

      @media (max-width: 500px) {
        right: -0.5vw;
      }
    }
  }

  .others-tu-language-header {
    border: none;
    color: #002169;
    font-size: 24px;
    cursor: pointer;
    appearance: none;
    z-index: 10;
    position: relative;
    background: transparent;

    @media (max-width: 992.98px) {
      font-size: 16px;
    }

    &:focus-visible {
      outline: none;
    }

    option {
      color: $light;
      background-color: #62171e;
    }
  }
}

//My wallet component
.tu-wallet-link {
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  color: #0a4e8f;
  margin-right: 40px;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  &:focus {
    box-shadow: none;
  }
}

.tu-common-modal.tu-wallet-modal {
  .modal-dialog {
    max-width: 732px;
    width: calc(100% - 30px);
    margin: 30px auto;
  }

  .modal-body {
    padding: 0 30px 52px;

    .tu-wallet-wrapper {
      margin-top: 30px;

      .tu-card {
        img {
          width: 90px !important;
          // object-fit: none !important;
          height: auto !important;
          border-bottom: none !important;
        }

        span {
          background-image: none !important;
          opacity: 60%;
        }

        .btn {
          opacity: 60%;
          cursor: inherit;
        }

        &.active {
          img,
          span,
          .btn {
            opacity: 1;
          }

          .btn {
            cursor: pointer;
          }
        }
      }
    }

    .btn-primary {
      border-radius: 12px;
      margin-top: 3px;
      font-size: 24px;
      font-weight: 400;
      width: 100%;
      cursor: pointer;
    }

    .tu-coins-cards {
      // display: flex;
      // justify-content: space-around;
      background: #0386c4;
      padding: 22px 10px;
      border-radius: 12px;
      position: relative;
      padding-bottom: 22px;

      .tu-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        justify-content: flex-end;
        position: relative;

        img {
          // padding-bottom: 22px;
          width: 100%;
          height: 73px;
          object-fit: contain;
          object-position: center;
          // border-bottom: 1px solid rgba(0, 32, 105, 0.2);
          margin-bottom: 15px;
          opacity: 40%;
        }

        span {
          font-weight: 700;
          font-size: 22px;
          line-height: 45px;
          width: 100%;
          display: block;
          text-align: center;
          background-image: url('~/public/assets/images/line.png');
          content: '';
          background-repeat: no-repeat;

          pre {
            margin: 0;
            position: absolute;
            right: 17px;
            overflow: hidden;
            top: 6px;

            input {
              position: absolute;
              top: 32%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 20px;
              opacity: 0;
              height: 20px;
            }

            label {
              width: 17px;
              height: 17px;
              border: 1px solid #fff;
              border-radius: 2px;

              &:after {
                content: '';
              }
            }

            input[type='radio']:checked + label {
              background: #fff;
            }

            input[type='radio']:checked + label:after {
              box-shadow: -2px 2px 0 #0386c4;
              width: 10px;
              height: 7px;
              transition: 0.5s;
              transform: rotate(-45deg);
              display: block;
              position: relative;
              left: 3px;
            }
          }
        }

        .btn {
          max-width: 87px;
          margin: 0 auto;
          padding: 8px 0;
          font-size: 16px;
        }

        &:first-child {
          border-right: 1px solid inset;
        }
      }
    }

    // This is for coins active section
    .my-victory-coins {
      .tu-card {
        label.my-victory-coins-label {
          padding: 0;
          display: block;
          margin: 0 0 15px 0;
          position: relative;

          img {
            margin: 0;
            height: inherit;
          }
        }

        span {
          position: relative;
        }

        &.active {
          label.my-victory-coins-label {
            &:after {
              width: 100%;
              height: 100%;
              box-shadow: 0 0 18px 10px #f5cc8e;
              display: block;
              border-radius: 100%;
              position: absolute;
              content: '';
              top: 0;
              left: 0;
            }

            img {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .header {
    .tu-profile-dropdown {
      .nav-link {
        padding: 0;
      }

      .thumbnail-image {
        width: 32px !important;
        height: 32px !important;
      }
    }

    .tu-wallet-link {
      margin-right: 12px;
      font-size: 20px;
      line-height: 23px;
    }

    //Uniques code component
    .tu-unique-code-wrapper {
      margin-right: 12px;
      margin-left: 12px;
      padding: 3px;

      input {
        font-size: 16px;
        line-height: 23px;
        width: 100px;
        padding-right: 5px;
      }

      .btn {
        font-size: 16px;
        padding: 6px 8px;
      }
    }
  }

  .tu-common-modal.tu-wallet-modal {
    // .modal-title,
    // .modal-sub-title {
    //   text-align: center;
    // }

    .modal-title {
      font-size: 40px;
    }

    .modal-sub-title {
      font-size: 28px;
    }

    .wallet-btn {
      display: flex;
    }

    .tu-coins-cards {
      // flex-wrap: wrap;
      justify-content: center !important;

      .tu-card {
        // width: 33.33% !important;

        img {
          border-bottom: 1px solid transparent !important;
        }

        // span {
        //   border-bottom: 1px solid rgba(0, 32, 105, 0.2);
        //   margin-bottom: 22px;
        // }

        &:last-child,
        &:nth-last-child(2) {
          span {
            border-bottom: 0;
            margin-bottom: 0;
          }

          img {
            border-bottom: 0;
          }
        }
      }

      &::after {
        content: '';
        // border-top: 1px solid rgba(0, 32, 105, 0.2);
        position: absolute;
        bottom: 45px;
        width: 100%;
      }
    }

    .tu-col-right {
      width: 100% !important;
      padding: 0;

      .tu-coins-cards {
        max-width: 137px;
        margin: 0 auto;

        .tu-card {
          img {
            border-bottom: 0;
            width: auto;
          }

          span {
            border-bottom: 0;
            margin-bottom: 0;
          }
        }
      }

      button.btn {
        margin-top: 20px !important;
      }
    }
  }

  .tu-wallet-wrapper {
    .tu-coins-cards {
      &::after {
        border-top: none !important;
      }

      .btn {
        max-width: 80%;
      }
    }
  }
}

.thumps-up-col {
  .tu-coins-cards {
    display: block !important;
    margin: 0 auto;
    text-align: center;

    .tu-cards {
      img {
        width: 76px;
      }

      span {
        font-weight: 700;
        font-size: 22px;
        line-height: 45px;
        width: 100%;
        display: block;
        text-align: center;
        background-image: url('~/public/assets/images/line.png');
        content: '';
        background-repeat: no-repeat;
        margin: 15px 0 0 0;
      }
    }
  }
}

.btn:disabled {
  opacity: 60%;
}

@media (max-width: 600px) {
  .tu-wallet-wrapper {
    .tu-coins-cards {
      .btn {
        max-width: 50% !important;
        font-size: 14px !important;
      }
    }
  }
}
